export default function CancellationRefundPolicyPage() {
    return (
        <>
            <div className="design-two pt-4">
                <div className="page-header terms-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header-box">
                                    <h1 className="text-anime">Cancellation and Refund Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>At Arihafoods Pvt. Ltd, we strive to provide our customers with the best dining experience, whether dining in our restaurant or ordering online for takeaway. However, we understand that there may be occasions where you need to cancel or modify your order. Please review our refund and cancellation policy below:</p>
                                <b>1. Cancellation Policy:</b>
                                <ul>
                                    <li>
                                        <b>For online orders:</b>
                                        <p>If the order could not be delivered to you after placing an order from our online platform, then it will be canceled and will proceed for refund. If you wish to cancel your order placed online, cancellations may not be possible as our kitchen may have already begun preparing your food.</p>
                                    </li>
                                    <li>
                                        <b>For takeaway orders: </b>
                                        <p>If you wish to cancel or modify your takeaway order, please contact us as soon as possible. We will do our best to accommodate your request, but please note that once food preparation has started, cancellations may not be possible.</p>
                                    </li>
                                </ul>
                                <b>2. Refund Policy:</b>
                                <ul>
                                    <li>
                                        <b>Refunds for online orders:</b>
                                        <p>You will receive a refund if we are unable to deliver the food to you due to any failure on our part. If you cancel your order within the specified time frame and payment has been made, we will process a refund to the original payment method used for the order. Please allow 3-5 business days for the refund to reflect in your account.</p>
                                    </li>
                                    <li>
                                        <b>Refunds for takeaway orders:</b>
                                        <p>
                                            Refunds for takeaway orders may be provided in the form of store credit or a refund to the original payment method, depending on the circumstances of the cancellation. Please contact us directly to discuss refund options.
                                        </p>
                                    </li>
                                </ul>
                                <b>3. Quality Concerns:</b>
                                <ul>
                                    <li>
                                        If you are dissatisfied with the quality of your food upon pickup, please bring it to our attention immediately. We will do our best to resolve the issue and ensure your satisfaction.
                                    </li>
                                </ul>
                                <b>4. Contact Us:</b>
                                <ul>
                                    <li>
                                        <p>
                                            If you need to cancel or modify your order, or if you have any questions or concerns about our refund and cancellation policy, please contact us at <b>+91 98844 55605</b>, we will do our best to resolve the issue.

                                        </p>
                                    </li>
                                    <b>
                                        5. Changes to Policy:
                                    </b>
                                    <li>
                                        Arihafoods Pvt. Ltd reserves the right to update or modify this refund and cancellation policy at any time without prior notice. Any changes will be effective immediately upon posting on our website or notification through other channels.
                                    </li>
                                </ul>
                                <p>
                                    By placing an order online or for takeaway from Arihafoods, you acknowledge that you have read, understood, and agree to abide by our refund and cancellation policy.
                                </p>
                                <p>
                                    Thank you for choosing Arihafoods. We appreciate your patronage and look forward to serving you delicious meals!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
