import axios from "axios";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import { MdOutlineMyLocation } from "react-icons/md";
import { store } from "../../../Store/Store";
import PostApiCall from "../../OnlineOrdering/helper/PostAPI";
import useGeoLocation from "../CustomHooks/useGeoLocation";
import Map from "../QuickSelection/Map";

function AddressOffcanvas(props) {
  const handleClose = () => props.setShow(false);
  const [currAddressFor, setCurrAddressFor] = useState("myself");
  const [currAddressType, setCurrAddressType] = useState("home");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandMark] = useState("");
  const [confirmAddress, setConfirmAddress] = useState(null);
  const [failureMessage, setFailureMessage] = useState("");
  const [lat, setLat] = useState(null);
  const [lang, setLang] = useState(null);
  const { mapAddress, setMapAddress, noDeliveryZone, setNoDeliveryZone, mapPostitionMarkerLat, setmapPostitionMarkerLat, mapPostitionMarkerLang, setmapPostitionMarkerLang,
    selectedLocation, setSelectedLocation, enterCompleteAddress, setEnterCompleteAddress, setAddNewAddress, clientCredentials, shippingCity, setShippingCity,
    shippingState, setShippingState } = useContext(store);
  useEffect(() => {

    if (props.addressId) {
      PostApiCall.postRequest(
        {
          columns: "*",
          whereClause: ` where fld_address_id = ${props.addressId}`,
        },
        "getCustomerAddress"
      ).then((result) =>
        result?.json().then((obj) => {
          if (result.status === 200 || result.status === 201) {
            if (props.addressId != null) {
              setName(obj.message[0].fld_name);
              setMobileNumber(obj.message[0].fld_contact_number);
              setPincode(obj.message[0].fld_pincode);
              setLandMark(obj.message[0].fld_landmark);
              setConfirmAddress(obj.message[0].fld_address_2)
              setCurrAddressType(obj.message[0].fld_address_type)
              console.log(obj.message[0].fld_address_type)
            } else {
              setName("");
              setMobileNumber("");
              setPincode("");
              setLandMark("");
            }
          }
        })
      );
    }

  }, []);

  const location = useGeoLocation();
  useEffect(() => {
    showCurrentLocation()
  }, [location])
  function showCurrentLocation() {

    try {
      if (location.loaded && !location.error) {
        setLat(location.coordinates.lat);
        setLang(location.coordinates.lng);
        axios
          .get(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            location.coordinates.lat +
            "," +
            location.coordinates.lng +
            `&key=${clientCredentials[0]?.fld_google_code}`
          )
          .then((res) => {
            setmapPostitionMarkerLat(res.data.results[0].geometry.location.lat);
            setmapPostitionMarkerLang(res.data.results[0].geometry.location.lng);
          });
      }
      //  else {
      //   console.log("error")
      //   // alert(location.error.message);
      // }
    } catch (error) {
      console.log(error)
    }

  }
  function showMyLocation() {
    try {
      if (location.loaded && !location.error) {
        setLat(location.coordinates.lat);
        setLang(location.coordinates.lng);
        try {
          axios
            .get(
              "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              location.coordinates.lat +
              "," +
              location.coordinates.lng +
              `&key=${clientCredentials[0]?.fld_google_code}`
            )
            .then((res) => {
              setmapPostitionMarkerLat(res.data.results[0].geometry.location.lat);
              setmapPostitionMarkerLang(res.data.results[0].geometry.location.lng);
              setMapAddress(res.data.results.filter(obj => obj.types.includes("sublocality"))[0]?.formatted_address);
              setShippingCity(res.data.results[0].address_components.find(component => component.types.includes('locality'))?.long_name)
              setShippingState(res.data.results[0].address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name)
              setFailureMessage("")
              getDistance()
            });
        } catch (error) {
          console.log(error)
        }

      }
      else {
        console.log("error")
        console.log(location.error.message);
      }
    } catch (error) {
      console.log(error)
    }

  }
  function getDistance() {
    var moilat = "28.647523";
    var moilng = "77.127684";
    var destinationLat = mapPostitionMarkerLat;
    var destinationLng = mapPostitionMarkerLang;

    PostApiCall.postRequest(
      {
        originLat: moilat,
        originLng: moilng,
        destinationLat: destinationLat,
        destinationLng: destinationLng,
      },
      "GoogleDistanceMatrix"
    ).then((results) => {
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // addAddress();
        } else {
          // setAddressData(null);
          setNoDeliveryZone(true);
        }
      });
    });
  }
  function onSaveAddress() {
    PostApiCall.postRequest(
      {
        CUSTOMERID: JSON.parse(localStorage.getItem("LoginDetailsWeb"))[0].CustomerId,
        addressid: props.addressId,
        title: currAddressType,
        name: name,
        mobileNo: mobileNumber,
        flatno: confirmAddress,
        area: mapAddress,
        landmark: landmark,
        pincode: pincode,
        action: props.addressId == null ? "INSERT" : "UPDATE",
        latitude: location.coordinates.lat,
        longitude: location.coordinates.lng,
        gstNumber: null,
        country: "India",
        city: shippingCity,
        state: shippingState,
      },
      "updatecustomeraddress"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status === 200 || resultcategory.status === 201) {
          Notiflix.Notify.Success(`Address ${props.addressId == null ? "Added" : "updated"} Sucessfully`
          );
          setAddNewAddress(false)
          // handleClose();
        }
      })
    );
  }
  return (
    <>
      {console.log(shippingCity, shippingState)}
      {(selectedLocation == false || mapAddress == null)
        // && enterCompleteAddress == false
        ?
        <Col lg={12} className="address-map address-map-main-box mb-4">
          <div className="mb-3 back-btn fs-4"><IoArrowBack onClick={() => { setAddNewAddress(false) }} className="me-2 cursor-pointer" /> Confirm delivery location</div>
          <div className="address-map-div">
            <Map lat={lat} lang={lang} />
            <Button
              variant="primary"
              type="button"
              className="btn bg-white border-danger text-theme me-lg-3"
              onClick={() => {
                showMyLocation();
              }}>
              <MdOutlineMyLocation /> Use current location
            </Button>
          </div>
          <p className="text-failure-message mt-3 mb-0 text-center fs-5">{failureMessage}</p>
          <div className="text-center">
            <Button
              variant="primary"
              type="button"
              className="btn btn-danger mt-3 mt-lg-4"
              onClick={() => {
                if (mapAddress) {
                  setSelectedLocation(true)
                  setEnterCompleteAddress(true)
                }
                else {
                  setFailureMessage("Please select location from map")
                }
              }}>
              Enter Complete Address
            </Button>
          </div>
        </Col>
        :
        <Form className="enter-address-form">
          <div className="d-flex fs-4 align-items-center mb-3">
            <span className="cursor-pointer d-flex align-items-start" onClick={() => {
              setEnterCompleteAddress(false)
              setSelectedLocation(false)
            }}>
              <IoArrowBack />
            </span>
            <h5 className="mb-0 ms-2 fs-4">
              Enter Complete Address
            </h5>
          </div>

          <div>
            <h6>Add an Address for</h6>

            <Form.Check
              inline
              checked={currAddressFor == "myself" ? true : false}
              onClick={() => setCurrAddressFor("myself")}
              name="group1"
              label="Myself"
              type={"radio"}
            />
            <Form.Check
              inline
              label="Someone Else"
              checked={currAddressFor == "someone else" ? true : false}
              onClick={() => setCurrAddressFor("someone else")}
              name="group1"
              type={"radio"}
            />
          </div>
          <div className="mt-3 mt-lg-2">
            <h6>Save Address as*</h6>

            <div className="mt-2 enter-add-btn">
              <Button
                variant="white"
                className={`kot-btn ${currAddressType == "home" ? "" : "bg-white text-dark border"
                  } me-2`}
                onClick={() => {
                  setCurrAddressType("home");
                }}
              >
                Home
              </Button>
              <Button
                variant="white"
                className={`kot-btn ${currAddressType == "work" ? "" : "bg-white text-dark border"
                  } me-2`}
                onClick={() => {
                  setCurrAddressType("work");
                }}
              >
                Work
              </Button>
              <Button
                variant="white"
                className={`kot-btn ${currAddressType == "hotel" ? "" : "bg-white text-dark border"
                  } me-2`}
                onClick={() => {
                  setCurrAddressType("hotel");
                }}
              >
                Hotel
              </Button>
              <Button
                variant="white"
                className={`kot-btn ${currAddressType == "other" ? "" : "bg-white text-dark border"
                  } me-2`}
                onClick={() => {
                  setCurrAddressType("other");
                }}
              >
                Other
              </Button>
            </div>

            <FloatingLabel
              label={currAddressFor == "myself" ? "Name" : "Receiver's name"}
              className="mb-3">
              <Form.Control
                id="outlined-basic-1"
                label={currAddressFor == "myself" ? "Name" : "Receiver's name"}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="mt-3 w-100"
              />
            </FloatingLabel>
            {currAddressFor == "someone else" &&
              <FloatingLabel
                label="Receiver's contact"
                className="mb-3">
                <Form.Control
                  id="outlined-basic-2"
                  label="Receiver's contact"
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                  className="mt-3 w-100"
                />
              </FloatingLabel>
            }
            <FloatingLabel
              label="Locality"
              className={
                noDeliveryZone == true
                  ? "col-lg-12 no-delivery-border mb-3"
                  : "col-lg-12 overflow-hidden mb-3"
              }>
              <Form.Control
                id="outlined-basic-4"
                type="text"
                placeholder=""
                className="col-11"
                value={mapAddress}
                onChange={(e) => {
                  setMapAddress(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              label="Flat, House no., Building, Company, Apartment etc."
              className="mb-3 overflow-hidden">
              <Form.Control
                id="outlined-basic-3"
                type="text"
                placeholder="Flat, House no., Building, Company, Apartment etc."
                value={confirmAddress}
                onChange={(e) => {
                  setConfirmAddress(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              label="Pincode"
              className="mb-3">
              <Form.Control
                id="outlined-basic-5"
                type="text"
                placeholder="Pincode"
                value={pincode}
                onChange={(e) => {
                  setPincode(e.target.value);
                }}
              />
            </FloatingLabel>
          </div>
          <div className="mt-3 text-center">
            <Button
              variant="white"
              className="kot-btn"
              onClick={() => {
                if (mapAddress != null) {
                  if (pincode != null) {
                    if (confirmAddress != null) {
                      onSaveAddress();
                      // getDistance();
                    } else {
                      Notiflix.Notify.Failure(
                        "Please confirm your address"
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter pincode.");
                  }
                } else {
                  Notiflix.Notify.Failure(
                    "Please detect your location"
                  );
                }
              }}
            >
              Save Address
            </Button>
          </div>
        </Form>
      }
      {/* </Offcanvas.Body> */}
      {/* </Offcanvas> */}
    </>
  );
}

export default AddressOffcanvas;
