import { useContext } from "react";
import { store } from "../Store/Store";
export default function ShippingDeliveryTermsPage() {
    const { keyStrings } = useContext(store);
    return (
        <>

            <div className="design-two pt-4">
                <div className="page-header terms-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header-box">
                                    <h1 className="text-anime">Delivery Terms</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <b>Delivery</b>
                                <p>
                                    Thank you for choosing Arihafoods Pvt. Ltd ! We are delighted
                                    to bring our delicious culinary creations to your doorstep.
                                    Please review the following information regarding our shipping
                                    and delivery policies:
                                </p>
                                <b>1. Delivery Zones:</b>
                                <p>
                                    We currently offer delivery services within a specified radius
                                    from our location. Please check our delivery zone
                                    map or contact us directly to inquire about delivery
                                    availability to your area.
                                </p>
                                <b>2. Delivery Hours:</b>
                                <p>
                                    Our delivery service is available during our regular
                                    operating hours. Delivery of orders will be done within 1 to 48 hours depending on the order
                                </p>
                                <b>3. Ordering Process:</b>
                                <p>
                                    To place a delivery order, simply visit our website or call
                                    directly. Select your desired items from our
                                    menu and provide your delivery address and contact information
                                    during the checkout process.
                                </p>
                                <b>4. Delivery Fees:</b>
                                <p>
                                    Delivery fees may apply to orders based on the distance from
                                    our location to your delivery address. The delivery
                                    fee will be clearly indicated during the checkout process
                                    before you confirm your order.
                                </p>
                                <b>5. Delivery Times:</b>
                                <p>
                                    While we strive to deliver your order in a timely manner,
                                    please note that delivery times may vary depending on factors
                                    such as traffic conditions and order volume. We appreciate
                                    your patience and understanding.
                                </p>
                                <b>6. Order Tracking:</b>
                                <p>
                                    For your convenience, we offer order tracking for delivery
                                    orders placed through our website. You will receive a
                                    notification with tracking information once your order is out
                                    for delivery.
                                </p>
                                <b>7. Special Instructions:</b>
                                <p>
                                    If you have any special delivery instructions or preferences,
                                    such as gate codes or specific delivery times, please include
                                    them in the "Special Instructions" section during the checkout
                                    process. We will do our best to accommodate your requests.
                                </p>
                                <b>8. Contactless Delivery:</b>
                                <p>
                                    In accordance with health and safety guidelines, we offer
                                    contactless delivery options to ensure the safety of our
                                    customers and delivery personnel. Your order will be left at
                                    your doorstep or designated delivery location for your
                                    convenience.
                                </p>
                                <b>9. Delivery Partners:</b>
                                <p>
                                    We partner with trusted delivery services to ensure that your
                                    order is delivered safely and efficiently. Our delivery
                                    partners are committed to providing excellent service and
                                    handling your order with care.
                                </p>
                                <b>10. Feedback:</b>
                                <p>
                                    We value your feedback! If you have any questions, concerns,
                                    or feedback regarding your delivery experience, please don't
                                    hesitate to contact us. Your input helps us improve our
                                    services and better serve you in the future.
                                </p>
                                <p>
                                    Thank you for choosing Arihafoods Pvt. Ltd for your dining
                                    needs. We look forward to delivering delicious meals straight
                                    to your doorstep!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}